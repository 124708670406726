import React, { useEffect } from 'react';
import './App.css';
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "routing";
import Auth from "_services/auth";
import API from "_services/api";
import Layout from "components/_Layout"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

const auth = new Auth();

const api = new API(auth);

process.env.NODE_ENV !== 'production' ? console.log("development") : console.log = () => { }

function App() {

  const dark = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark', //light
          primary: {
            main: "#4791db"
          },
          background: {
            default: "#808080"
          }
        },
      },esES),
  );

  const light = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'light',
          background: {
            default: "#a9a9a9"
          }
        },
      },esES),
      );

  const defaultTheme = dark
  return (
    <ThemeProvider theme={defaultTheme}>
      <HashRouter
        key={auth.token}
        basename="/app"
        hashType="noslash"
      >
        <Switch>
          {routes.map((route, index) => {
              return (
                <AppRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  routes={routes}
                  route={route}
                  publicRoute={route.public}
                  component={route.component}
                />
              )
          })}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
}

const AppRoute = ({ routes: routes, publicRoute: PublicRoute, route: route, component: Component, ...rest }) => {
  if (PublicRoute) {
    return (
      <Route {...rest} render={props => {
        return (
          <Component auth={auth} {...props} />
        )
      }}
      />
    )
  } if ((route.privilege_index !== undefined && !auth.privileges[route.privilege_index])) 
  {
    return (
      <Redirect to="/" />
    )
  }
  else {
  return (
    <Route {...rest} render={props => {
      if (!auth.isLogedIn()) {
        props.history.push("/ingresar")
      }
      else {
        return (
          <Layout auth={auth} routes={routes} {...props}>
            <Component auth={auth} api={api} {...props} />
          </Layout>
        )
      }
    }
    }
    />
  )
}
}

export default App;
