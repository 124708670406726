import { makeStyles } from '@material-ui/core/styles';
import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from "components/DatePicker";
import Fab from '@material-ui/core/Fab';

import RemoveIcon from '@material-ui/icons/Remove';


const useStyles = makeStyles((theme) => ({
    input: {
        width: "100%"
    }

}));

function CustomTextField(props) {
    const classes = useStyles();
    return (<TextField className={classes.input} variant="outlined" {...props} />)
}

function CustomSelect(props){
    const classes = useStyles();
    return(
        <FormControl variant="outlined" className={classes.input}>
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <Select
                id={props.id}
                className={classes.input}
                value={props.value}
                label={props.label}
                inputProps={{
                    id: props.id,
                }}
                error = {props.error}
                onChange={(e)=>{e.target.id = props.id; props.onChange(e)}}
                disabled={(props.data?.length) ? false : true}
            >
                {props.data && props.data.map((value, i)=>{
                    return(<MenuItem key={value} value={value}>{value}</MenuItem>)
                })}
            </Select>
        </FormControl>
    )

}

function getData(categorie, categories, key){
    var select_data = []
    if (categorie) {
        for (var c of categories) {
            if (c.nombre === categorie) {
                select_data = c[key].map((o) => (o.nombre))
            }
        }
    }
    return select_data
}

export default function WorkForm(props) {
    const classes = useStyles();
    var categories = []
    const [state, setState] = useState({
        ["input-store-branch"]: "",
        ["input-first-categorie"]: "",
        ["input-categorie"]: "",
        ["input-object"]: "",
        ["input-brand"]: "",
        ["input-work"]: "",
        ["input-note"]: "",
        ["input-delivery"]: null,
        ["input-value"]: "",
        ["input-payment"]: "",
        ["input-payment-bill"]: "",

    })
    var handleChange = (name, value, e=null)=>{
        var originalPosition = e?.target?.inputPosition ? "" + e.target.inputPosition : ""
        props.errors[name+"-error"] = false
        if(typeof value === "string"){
            value = value.toUpperCase()
        }
        if (name === "input-value"){
            props.updateTotalValue(props.index, value)
        }
        setState({ ...state, [name]: value})
        if (e && e.target?.inputPosition) {
            window.requestAnimationFrame(() => {
                e.target.selectionStart = originalPosition
                e.target.selectionEnd = originalPosition
            })
        }
    }
    var categories = props.categories.filter((c) => {
        let selectedFirstCategory = state["input-first-categorie"];

        if (selectedFirstCategory === c.primera_categoria?.nombre) {
            return c
        }
    }).map((c) => (c.nombre))
    var firstCategories = props.firstCategories.map((fc) => (fc.nombre))
    var storeBranches = props.storeBranches.map((sb) => (sb.nombre))

    props.onChange(props.index, state)
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <CustomSelect 
                        onChange={(e) => {
                            props.errors["input-store-branch-error"] = false
                            setState({
                                ...state,
                                ["input-store-branch"]: e.target.value,
                             })
                        }} 
                        id="input-store-branch"
                        error={props.errors["input-store-branch-error"]}
                        label="Sucursal"
                        data={storeBranches}
                        value={state["input-store-branch"]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomSelect 
                        onChange={(e) => {
                            props.errors["input-first-categorie-error"] = false
                            setState({
                                ...state,
                                ["input-object"]: "",
                                ["input-brand"]: "",
                                ["input-categorie"]: "",
                                ["input-first-categorie"]: e.target.value,
                             })
                        }} 
                        id="input-first-categorie"
                        error={props.errors["input-first-categorie-error"]}
                        label="Primera Categoría"
                        data={firstCategories}
                        value={state["input-first-categorie"]}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomSelect 
                        onChange={(e) => {
                            props.errors["input-categorie-error"] = false
                            setState({
                                ...state,
                                ["input-object"]: "",
                                ["input-brand"]: "",
                                ["input-categorie"]: e.target.value,
                             })
                        }} 
                        id="input-categorie"
                        error={props.errors["input-categorie-error"]}
                        label="Categoría"
                        data={categories}
                        value={state["input-categorie"]}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomSelect 
                        onChange={(e) => {
                            handleChange("input-object", e.target.value)
                        }}
                        id="input-object" 
                        error={props.errors["input-object-error"]}
                        label="Objeto" 
                        data={getData(state["input-categorie"], props.categories, "objetos")}
                        value={state["input-object"]}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomSelect 
                        onChange={(e) => {
                            handleChange("input-brand", e.target.value)
                        }}
                        id="input-brand"
                        error={props.errors["input-brand-error"]}
                        label="Marca" 
                        data={getData(state["input-categorie"], props.categories, "marcas")}
                        value={state["input-brand"]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField 
                        onChange={(e) => {
                            handleChange("input-work", e.target.value, e)
                        }}
                        onInput={((e) => { e.target.inputPosition = e.target.selectionStart})}
                        value={state["input-work"]} 
                        id="input-work" 
                        label="Trabajo"
                        helperText="Máximo 50 caracteres"
                        inputProps={{maxLength: 50}}
                        error={props.errors["input-work-error"]} 
                        multiline
                        rows={2}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField
                        onChange={(e) => {
                            handleChange("input-note", e.target.value)
                        }}
                        onInput={((e) => { e.target.inputPosition = e.target.selectionStart })}
                        value={state["input-note"]}
                        id="input-note"
                        label="Observaciones"
                        helperText="Máximo 200 caracteres"
                        inputProps={{ maxLength: 200 }}
                        error={props.errors["input-note-error"]}
                        multiline
                        rows={2}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DatePicker 
                        onChange={(e) => {
                            handleChange("input-delivery", e)
                        }}
                        id="input-delivery"
                        error={props.errors["input-delivery-error"]}
                        label="Entrega"
                        disablePast={true}
                        style={{ marginTop: "0px", width: "100%" }}
                        value={state["input-delivery"]}
                        autoOk={true}
                        emptyLabel=""
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField onChange={(e) => handleChange("input-value", e.target.value)} value={state["input-value"]} id="input-value" label="Valor" type="number" error={props.errors["input-value-error"]} />
                </Grid>
                {props.index === 1 &&
                <Grid item xs={3}>
                    <CustomTextField onChange={(e) => handleChange("input-payment", e.target.value)} value={state["input-payment"]} id="input-payment" label="Abono" type="number" error={props.errors["input-payment-error"]} />
                </Grid>
                }
                {props.index === 1 &&
                <Grid item xs={3}>
                    <CustomTextField onChange={(e) => handleChange("input-payment-bill", e.target.value)} value={state["input-payment-bill"]} id="input-payment-bill" label="Boleta abono" type="number" error={props.errors["input-payment-bill-error"]} />
                </Grid>
                }
                {props.index !== 1 &&
                <Grid item xs={12} container={true} alignItems="center" justify="center">
                    <Fab
                        color={"secondary"}
                        aria-label="add"
                        onClick={() => {
                            props.delete(props.index)
                        }}
                    >
                        <RemoveIcon />
                    </Fab>
                </Grid>
                }
            </Grid>
        </React.Fragment>
    );
}
