import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import IdForm from 'components/IdForm';
import NavBar from 'components/Navbar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: theme.palette.background.default,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function Main() {
  const classes = useStyles();
  return (
    <div>
      
    <NavBar />
    
    <Box className={classes.content}>
    <Container maxWidth="lg" className={classes.container}>
    <IdForm />
    </Container>
    </Box>
    </div>
  );
}
