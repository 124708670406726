import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Table } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import generatePDF from "_services/generatePDF"
import functions from "_services/functions"

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
    custom_style: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
    },
  },
}));



export default function JobDetails(props) {
  const classes = useStyles();
  const row = props.row;
  const workData = props.allData?.filter(function (d) { return (d.id === row.id) })[0];
  const historial = workData?.historial;
  const finalState = workData?.estado.es_final;
  const initialState = props.initialState.nombre === workData.estado.nombre;
  const printDocState = initialState;
  const [additionalFee, setAdditionalFee] = React.useState(workData ? workData.costo_adicional : "");
  const [success, setSuccess] = React.useState(false);
  const [gotResult, setGotResult] = React.useState(false);
  const [initialWeight, setInitialWeight] = React.useState(workData ? workData.peso_inicial : "");
  const [finalWeight, setFinalWeight] = React.useState(workData ? workData.peso_final : "");
  const [description, setDescription] = React.useState(workData ? workData.descripcion_costo_adicional : "");

  var abono_consolidado = workData.documento.abono_consolidado.filter(e => e.trabajo.id === workData.id)
  var valor_abono = null
  if (abono_consolidado.length > 0) {
    valor_abono = abono_consolidado[0].valor_consolidado
  }

  var setUpperDescription = (e) => {
    var value = e.target.value
    if (typeof value === "string") {
      value = value.toUpperCase()
    }
    setDescription(value)
    if (e.target?.selectionStart !== undefined && e.target?.inputPosition !== undefined) {
      window.requestAnimationFrame(() => {
          e.target.selectionStart = e.target.inputPosition
          e.target.selectionEnd = e.target.inputPosition
      })
  }
  }

  const handleUpdateValues = async () => {
    if (additionalFee || initialWeight || finalWeight || description) {
      var inputAdditionalFee = additionalFee ? additionalFee : workData.costo_adicional;
      var inputInitialWeight = initialWeight ? initialWeight : workData.peso_inicial;
      var inputFinalWeight = finalWeight ? finalWeight : workData.peso_final;
      var inputDescription = description ? description : workData.descripcion_costo_adicional;

      setSuccess(await props.updateWorkValues(row.id, inputAdditionalFee, inputInitialWeight, inputFinalWeight, inputDescription));
      setGotResult(true);
    }
  }
  const handleSubmit2 = () => {
    console.log(props.allData)
    var data = {}
    for (let d of props.allData) {
      if (d.id === row.id) {
        data = d
        break
      }

    }

    console.log(data)
    try {
      generatePDF({
        seguimiento: row.doc_id,
        id: data.id,
        objeto: data.objeto,
        marca: data.marca,
        telefono: data.cliente.telefono,
        trabajo: data.trabajo,
        categoria: data.categoria,
        valor: data.valor,
        abono: data.abono ? data.abono : "0",
        nombre: data.cliente.nombre + " " + data.cliente.apellido,
        observacion: data.historial.length > 0 ? data.historial[data.historial.length - 1].nota : "",
        recepcion: functions.dateToLocalString(data.created_at.split("T")[0]),
        entrega: functions.dateToLocalString(data.entrega),
        documento: data.documento,
      },
      props.api
      )
    }
    catch (e) { console.log(e) }
    props.handleClose()
  }
  // Formulario de cambio de estado

  if (!printDocState) {
    if (!gotResult)
      return (
        <div>
          <form onSubmit={handleUpdateValues}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <h2 id="transition-modal-title">Historial</h2>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size='small' aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align='left'><b>Estado</b></TableCell>
                        <TableCell align='left'><b>Nota</b></TableCell>
                        <TableCell align='left'><b>Fecha</b></TableCell>
                        <TableCell align='left'><b>Encargado</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historial?.map((h) => (
                        <TableRow key={h.name}>
                          <TableCell align="left">{h.estado?.toUpperCase()}</TableCell>
                          <TableCell align="left">{h.nota?.toUpperCase()}</TableCell>
                          <TableCell align="left">{h.fecha}</TableCell>
                          <TableCell align="left">{h.encargado?.toUpperCase() }</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {finalState &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h2 id="transition-modal-title">Resumen</h2>
              </Grid>
              <Grid item xs={12} style={{textAlign: "center"}}>
                <h3>Valor: {functions.amountFormat(workData.valor)}</h3>
                {valor_abono ? <h3>Abono: {functions.amountFormat(valor_abono)}</h3> : ""}
              </Grid>
            </Grid>
            }
            {!finalState &&
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Costo adicional"
                  id="filled-margin-dense-required"
                  className={classes.textField}
                  margin="dense"
                  variant="filled"
                  value={additionalFee}
                  type="number"
                  onChange={event => {
                    setAdditionalFee(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Peso inicial (g)"
                  id="filled-margin-dense-required"
                  className={classes.textField}
                  margin="dense"
                  variant="filled"
                  value={initialWeight}
                  type="number"
                  onChange={event => {
                    setInitialWeight(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Peso final (g)"
                  id="filled-margin-dense-required"
                  className={classes.textField}
                  margin="dense"
                  variant="filled"
                  value={finalWeight}
                  type="number"
                  onChange={event => {
                    setFinalWeight(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descripción"
                  id="filled-margin-dense-required"
                  className={classes.textField}
                  margin="dense"
                  variant="filled"
                  value={description}
                  type="number"
                  onChange={event => {
                    setUpperDescription(event)
                  }}
                  onInput={((e) => { e.target.inputPosition = e.target.selectionStart })}
                  multiline
                  rows={4}
                />
              </Grid>


              <Grid item xs></Grid>
              {
                (additionalFee || initialWeight || finalWeight) &&
                <Button type="submit">Guardar</Button>

              }
              {
                (!additionalFee && !initialWeight && !finalWeight) &&
                <Button onClick={(evt) => props.handleClose()}>Cerrar</Button>

              }
            </Grid>
            }
          </form>
        </div>
      );
    if (gotResult && success)
      return (
        <Grid container direction="row"
          justify="space-evenly"
          alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>Operación realizada con éxito.</h2>
          </Grid>

          <Grid item xs>
          </Grid>

          <Button onClick={e => props.handleClose()} >Ok</Button>

        </Grid>
      )
    if (gotResult && !success)
      return (
        <Grid container direction="row"
          justify="space-evenly"
          alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>Hubo un error al realizar la operación.</h2>
          </Grid>

          <Grid item xs>
          </Grid>

          <Button onClick={e => props.handleClose()} >Ok</Button>

        </Grid>
      )
  }
  // Mensaje de éxito de cambio de estado final
  if (printDocState) return (
    <form className={classes.custom_style} onSubmit={handleSubmit2}>
      <Grid container direction="row"
        justify="space-evenly"
        alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <h3 style={{ textAlign: 'center' }}>¿Desea imprimir el comprobante de trabajo?</h3>
        </Grid>
        <Grid item><Button type="submit" >Sí</Button></Grid>
        <Grid item><Button onClick={(evt) => props.handleClose()} >No</Button></Grid>


      </Grid>
    </form>
  )
}