import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Collapse, Grid } from '@material-ui/core';
import JobPublicDetails from './JobPublicDetails';
import axios from 'axios';
import config from '_services/config';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    temp_container:{
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    paper:{
      width: '100%'
  },
  subm_button:{
    background: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
},
  }));
  

  export default function IdForm() {
    const classes = useStyles();
        
    const [checked,setCheck] = React.useState(false);
    const [rut,setRut] = React.useState('');
    const [docId,setDocId] = React.useState('');
    const [works,setWorks] = React.useState('');
    const [error, setError] = React.useState('');

    const getData = async (id,rut) => {
      try {
        console.log(typeof id,typeof rut,id,rut)
          var { data, error } = await axios.post(config.API + "/trabajos/public",{identificador:id,rut:rut});
          if (error) throw "Error al obtener trabajo";
          console.log(data);
          return(data);
         

      } catch (e) { 
        console.log(e)
        setError("Error al obtener el trabajo.")
      }
    }
    
    const handleSubmit = async (evt) => {
        setError("")
        evt.preventDefault();
        var data = await getData(docId,rut);
        if(data){
          setWorks(data);
          setCheck((prev) => true);
        }
        
    }
    
    const rut_pattern = new RegExp("(^$|^[0-9]{1,}([0-9]|k|K)?$)");

    const handleChange = (e) => {
      var value = e.target.value
      value = value.replace("-", "");
      if (!rut_pattern.test(value)) {
              console.log("wrong rut pattern")
              console.log(rut)
              value = rut ? rut : ""
          }
      if (value.length > 1) {
              value = value.replace("-", "");
              value = [value.slice(0, value.length - 1), "-", value.slice(value.length - 1)].join('');
          }
          
      
          value = value.toUpperCase()
      setRut(value);
  }    
  
    return (
      
          <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
        <Paper className={classes.paper}>
            <div className={classes.temp_container}>
            <Grid 
            container 
            alignItems="baseline"
            justify="space-around"
            direction = "row">
          <TextField
          required
            label="Rut"
            id="filled-margin-dense-required"
            className={classes.textField}
            helperText="Ingrese rut sin puntos."
            margin="dense"
            variant="filled"
            pattern="[0-9]"
            value={rut}
            //onChange = {event => setRut(event.target.value.replace(/\D/,''))}
            onChange={handleChange}
          />
          <TextField
          required
            label="Número de seguimiento"
            id="filled-margin-dense"
            className={classes.textField}
            margin="dense"
            variant="filled"
            pattern="[0-9]"
            value={docId}
            onChange = {event => setDocId(event.target.value.replace(/\D/,''))}
          />
          <Button className={classes.subm_button} variant="contained"  type="submit">
            Consultar
          </Button>
          </Grid>
          <div className={classes.temp_container}>
          <Collapse in={checked && !error}>
            {works &&
            works?.map((w) => (
              <JobPublicDetails job={w} />
            ))}
            
          </Collapse>
          {error}
          </div>
          </div>
          </Paper>  
          
 
        </form>
    );
  }