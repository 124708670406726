import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles({
    root: {
        minHeight: "250px",
        padding: "10px 50px"
    },
});

export default function MarkDownCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="body2" component="div">
                    <ReactMarkdown>{props.text}</ReactMarkdown>
                </Typography>
            </CardContent>
        </Card>
    );
}