import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

export default function DatePicker(props){
    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                cancelLabel="Cancelar"
                {...props}

            />
        </MuiPickersUtilsProvider>
    )

}

