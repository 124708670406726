import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import functions from "_services/functions"

const useStyles = makeStyles((theme) => ({
    root: {
      //display: 'flex',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      '& svg': {
        margin: theme.spacing(1.5),
      },
      '& hr': {
        margin: theme.spacing(0, 0.5),
      },
    },
    table: {
      //minWidth: 650,
    },
}));


function createData(name, val) {
  return { name, val };
}

export default function JobPublicDetails(props) {
    const classes = useStyles();
    const job = props.job;
    const rows = job ? [
      createData('Objeto', job.objeto),
      createData('Marca', job.marca),
      createData('Trabajo', job.trabajo),
      createData('Recepción', functions.dateToLocalString(job.recepcion.split("T")[0])),
      createData('Entrega', functions.dateToLocalString(job.entrega)),
      createData('Estado', job.estado),
    ] :
    [];
  return (
    
    <div style={{marginTop: "20px"}}>
      <TableContainer component={Paper}>
      <Table className={classes.table} size='small' aria-label="simple table">
      <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <b>{row.name}</b>
              </TableCell>
              <TableCell width = {"80%"} align="left">{row.val}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </div>  );
}