import { Grid} from "@material-ui/core"
import React from 'react';
import { Component } from 'react';


export class JobDocument extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rows: [],
			noteAtEnd: false,
		}
	}

	createData(rut, object, trademark, job, stateNote, employee, id, last_state, state, changeStateId) {
		return { rut, object, trademark, job, stateNote, employee, id, last_state, state, changeStateId};
	}

	componentDidUpdate(prevProps) {
		var rows = [];
		if (prevProps.selectedData !== this.props.selectedData || prevProps.noteAtEnd !== this.props.noteAtEnd) {
			for (let i = 0; i < this.props.selectedData.length; i++) {
				const row = this.props.selectedData[i];
				const state = row.historial[row.historial.length - 1]
				const last_state = row.historial.length === 1 ? '' : row.historial[row.historial.length - 2]
				rows.push(this.createData(row.cliente.rut, row.objeto, row.marca, row.trabajo, state?.nota, state?.encargado, row.id, last_state?.estado, state?.estado, row.historial.length > 0 ? row.historial[row.historial.length - 1]?.cambio_de_estado?.id: null));

			}
			this.setState({ rows: rows, noteAtEnd: this.props.noteAtEnd });
		}


	}
	render() {
		const thTdStyle = {
			border: "2px solid #000000",
			textAlign: "left",
			padding: "8px 3px",
			color: "#000000"
		}

		const tableStyle = {
			borderCollapse: "collapse",
			width: "98%",
			fontSize: "12px",
		}
		const containerStyle = {
			font: 'arial,sans-serif', color: "#000000", marginLeft: "50px", marginTop: "35px"
		}
		const signatureStyle = {
			textAlign: "center"
		}

		var data = [
			{ attr: "id", title: "Folio" },
			{ attr: "object", title: "Objeto" },
			{ attr: "trademark", title: "Marca" },
			{ attr: "job", title: "Trabajo" },
			{ attr: "last_state", title: "Desde" },
			{ attr: "employee", title: "Recepciona" },
			{ attr: "state", title: "Hacia" }
		]

		var sameNote = true
		var sameEmployee = true
		var first = true
		var note = null
		var employee = null
		var stateChangeIds = []
		for (let i of this.state.rows) {
			console.log(i.job, i.changeStateId)
			if (first) {
				note = i.stateNote
				employee = i.employee
				first = false
			}
			if (note != i.stateNote) {
				sameNote = false
			}
			if (employee != i.employee) {
				sameEmployee = false
			}
			stateChangeIds.push(i.changeStateId)

		}
		console.log(stateChangeIds)
		if(employee == "NO APLICA"){
			sameEmployee = false
		}

		if (this.state.noteAtEnd === false && sameNote === false) {
			data.push({ attr: "stateNote", title: "Nota" })
		}

		return (
			<div style={containerStyle}>
				<h2 style={{ marginBottom: "10px" }}>Guía Traspaso</h2>
				<h4 style={{ marginBottom: "10px" }}>Folio(s): {stateChangeIds.join(' - ')}</h4>
				<table style={tableStyle}>
					<tr>
						{data.map((e) => (<th style={thTdStyle}>{e.title}</th>))}
					</tr>

					{this.state.rows.map((row) => (
						<tr>
							{data.map((e) => (<th style={thTdStyle}>{typeof row[e.attr] === "string" ? row[e.attr].toUpperCase() : row[e.attr] }</th>))}
						</tr>
					))}

				</table>
				{(this.state.noteAtEnd || sameNote) &&
					<div style={{width: "98%"}}>
						<h2 style={{ marginBottom: "2px" }}>Nota</h2>
						<table style={{
							borderCollapse: "collapse",
							width: "100%"
						}}>
							{this.state.rows.length > 0 &&
								<tr>
									<td style={thTdStyle}>{this.state.rows[0].stateNote}</td>
								</tr>
							}
						</table>
					</div>
				}
				<Grid container style={{marginTop: "100px", width: "90%"}}>
					<Grid item xs={6} style={signatureStyle}>
						{this.props.auth.getUserName() && this.props.auth.getUserName().toUpperCase() }
					</Grid>
					
					<Grid item xs={6} style={signatureStyle}>
					{sameEmployee && employee && employee.toUpperCase() }
					</Grid>
					<Grid item xs={6} style={signatureStyle}>
						______________________________
					</Grid>
					
					<Grid item xs={6} style={signatureStyle}>
					{sameEmployee && "______________________________"}
					</Grid>
				</Grid>
			</div>
		);
	}
}
