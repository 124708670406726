import axios from 'axios';
import config from "./config";

const cache = {
    get: {},
    post: {}
}

class API {

    config = {
        headers: {
          Authorization: null
        }
    }

    constructor(auth){
        this.auth = auth
        if(auth.isLogedIn()){
            this.config.headers.Authorization = "Bearer " + auth.getToken()
        }
    }


    getHeader(){
        if(this.auth.isLogedIn()){
            return {
                headers: {
                    Authorization: "Bearer " + this.auth.token
                }
            }
        }
        else{
            return null
        }

    }



    async get(url, useCache=false){
        try{
            if(cache.get[url] && useCache){
                return {data: cache.get[url]};
            } 
            var response = await axios.get(config.API + url, this.getHeader())
            var data = response.data
            if (useCache) {
                cache.get[url] = data
            }
            return {data: data}
        }
        catch(e){
            //console.log(e.response.data)
            if(e?.response?.status === 401){
                this.auth.logout()
            }
            else{
                return {error: e}
            }
        }
        
    }

    async getAll(urls, useCache=false){
        try{
            var name = urls.join(",")
            if(cache.get[name] && useCache){
                return {data: cache.get[name]};
            }
            var requests = urls.map((url)=>(axios.get(config.API + url, this.getHeader())))
            var response = await axios.all(requests)
            var data = response.map((r) => (r.data))
            if(useCache){
                cache.get[name] = data
            }
            return {data: data}
        }
        catch(e){
            if(e.response?.status === 401){
                this.auth.logout()
            }
            else{
                return {error: e}
            }
        }
        
    }

    async post(url, data, useCache=false){
      try{
          if (cache.post[url] && useCache) {
              return { data: cache.post[url] };
          }
          var response = await axios.post(config.API + url, data, this.getHeader())
          var data = response.data
          if (useCache) {
              cache.post[url] = data
          }
          return { data: data }
      }
      catch (e) {
          //console.log(e.response.data)
          if (e.response.status === 401) {
              this.auth.logout()
          }
          else {
              return { error: e }
          }
      }
        
    }

    async put(url, data, useCache = false) {
        try {
            if (cache.post[url] && useCache) {
                return { data: cache.post[url] };
            }
            var response = await axios.put(config.API + url, data, this.getHeader())
            var data = response.data
            if (useCache) {
                cache.post[url] = data
            }
            return { data: data }
        }
        catch (e) {
            //console.log(e.response.data)
            if (e.response.status === 401) {
                this.auth.logout()
            }
            else {
                return { error: e }
            }
        }

    }
}

export default API;