import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import TableToExcel from "@linways/table-to-excel";
import React from 'react';

const attributes = {
    id: "",
    categoria: "",
    objeto: "",
    marca: "",
    trabajo: "",
    entrega: "",
    valor: "",
    abono: "",
    observaciones: "",
    cliente: "rut",
    costo_adicional: "",
    boleta_abono: "",
    boleta_saldo: "",
    estado: "",
    fecha: "",
    nota: "",
    encargado: "",
    peso_inicial: "",
    peso_final: ""
}

function history_reducer(data){
    var new_data = []
    for(let d of data){
        var history = d.historial
        // If no history push data
        if(!history || history.length===0){
            new_data.push(d)
            continue
        }
        // Add a row per history
        for(let h of history){
            var temp = {...d}
            for(let k of Object.keys(h)){
                if(k!=="id"){
                    temp[k] = "" + h[k]
                }
            }
            new_data.push(temp)
        }
    }
    return new_data
}


export default function ExcelGenerator(props) {
    var data = props.data ? history_reducer(props.data) : []
    var keys = data[0] ? Object.keys(attributes) : []
    return (
        <React.Fragment>
            <table id="excelTable"  style={{ display: "none" }}>
                <thead>
                    <tr>
                        {keys.map((name, i) => (<th key={i} data-a-h={"center"}>{name}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, i) => {
                        return (
                            <tr key={i}>
                                {keys.map((key, i) => {
                                    if (row[key] && typeof row[key] === "object"){
                                        return (attributes[key] ? <td key={i}>{row[key][attributes[key]]}</td> : <td key={i}>{row[key].id}</td>)
                                    }
                                    return (<td key={i}>{row[key]}</td>)
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Button
                id="excelGeneratorButton"
                style={{ display: "none" }}
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => { TableToExcel.convert(document.querySelector("#excelTable"), { name: "Download.xlsx" }) }}
            >
                Descargar
            </Button>
        </React.Fragment>
    );
}
