import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import JobTable from 'components/JobTable';
import { Button, Paper } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import functions from '_services/functions';
import { Grid } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: theme.palette.background.default,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
      padding: theme.spacing(2),
  },
  subm_button:{
    background: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
}
}));

function createData(object, trademark, job, state, state_level, client, client_name, return_date, id, doc_id, description, updated_at, is_final, category) {
    return {object, trademark, job, state, state_level, client, client_name, return_date, id, doc_id, description, updated_at, is_final, category};
  }




export default function Jobs(props) {
  const classes = useStyles();
  const [allRows,setAllRows] = React.useState('');
  const [search,setSearch] = React.useState('');
  const [idSearch,setIdSearch] = React.useState('');
  const [rutSearch,setRutSearch] = React.useState('');
  const [documentSearch,setDocumentSearch] = React.useState('');
  const [nameSearch,setNameSearch] = React.useState('');
  const [rows,setRows] = React.useState([]);
  const [states,setStates] = React.useState('');
  const [allData,setAllData] = React.useState('');
  const [staff, setStaff] = React.useState(null);
  const [storeBranches,setStoreBranches] = React.useState(null);
  const [categories,setCategories] = React.useState(null);
  const [firstCategories,setFirstCategories] = React.useState(null);
  const [selectedStoreBranch,setSelectedStoreBranch] = React.useState(null);
  const [selectedCategory,setSelectedCategory] = React.useState('');
  const [selectedFirstCategory,setFirstSelectedCategory] = React.useState('');
  const [selectedObject, setSelectedObject] = React.useState('');
  const [selectedBrand, setSelectedBrand] = React.useState('');
  const [selectedState, setSelectedState] = React.useState('');
  const [incomingStates, setIncomingStates] = React.useState(null);
  const [outgoingStates, setOutgoingStates] = React.useState(null);
  const [filterSwitch,setFilterSwitch] = React.useState(true);
  const [sentWorks,setSentWorks] = React.useState([]);
  const [initialState, setInitialState] = React.useState("");
  const [advancedSearchOpen,setAdvancedSearchOpen] = React.useState(false);
  

  // Supervisor
  const userIncomingStates = props.auth.getAccessLevelsIn()
  const userOutgoingStates = props.auth.getAccessLevelsOut()
  
  
  
  const filterList = (rows, str, clientStr, idStr, docStr, nameStr) => {
    if (!rows || rows.length===0) return [];
    if (!str && !clientStr && !idStr && !docStr && !nameStr) return rows;
    const searchableFields = ['object', 'trademark', 'id', 'job', "name"]

    str = str.toLowerCase()
    var filteredList = []

    for (let r of rows) {
      var foundClient = clientStr ? false: true;
      var foundId = idStr ? false : true;
      var foundSearch = str ? false : true;
      var foundName = nameStr ? false : true;
      var foundDoc = docStr ? false : true;

      if (clientStr){
        if (r['client'].toString().includes(clientStr)) foundClient = true;
      }
      // Devuelve un único folio pues es búsqueda exacta
      if (idStr){
        if (r['id'].toString() === idStr) foundId = true;
      }
      if (docStr){
        if (r['doc_id'] === docStr) foundDoc = true;
      }
      if (nameStr){
        if (r['client_name'].includes(nameStr.toUpperCase())) foundName = true;
      }
      if (str){
        for (var e in r) {
          if (!searchableFields.includes(e)) continue;

          if (e && (typeof r[e] === "string")) {

            if (r[e].toLowerCase().includes(str)) {
              foundSearch = true;
              break;
            }
          }
        }
      }
      if (foundClient && foundId && foundSearch && foundDoc && foundName){
        filteredList.push(r);
      }
    }
    return filteredList
  }
  const filterCategories = () => {
    if (!selectedFirstCategory || selectedFirstCategory === "TODAS"){
      return categories;
    }
    
    return categories.filter(category => category.primera_categoria?.nombre === selectedFirstCategory.nombre);
  }
  const getData = async () => {
    try {
      var response = await props.api.get("/trabajos?_limit=-1");
        if (response.error) throw new Error("Error al obtener trabajos");
        setAllData(response.data);
        var jobs = []
        for (let index = 0; index < response.data.length; index++) {
            const job = response.data[index];
            if (!userIncomingStates.includes(job.estado.nivel)) continue;
          var identifier = job.documento ? job.documento.identificador + job.documento.id.toString() : ""
            jobs.push(createData(job.objeto,job.marca,job.trabajo,job.estado?.nombre,job.estado?.nivel,job.cliente.rut,`${job.cliente?.nombre} ${job.cliente?.apellido}`,job.entrega,job.id,identifier,job.observaciones,job.updated_at,job.estado?.es_final, job.categoria));
        }

        setAllRows(jobs);
        setRows(jobs);
        

        
    } catch (e){console.log(e)}
  }

  const changeState = async (id,state,employee,stateNote) => {
    try{
      var response = await props.api.get("/trabajos/"+id);
      if (response.error) throw new Error("Error al obtener trabajo");
      
      response.data.estado = state;
      response.data.historial.push({estado: state.nombre, fecha: functions.dateToString(new Date()),encargado: employee, nota: stateNote });
      response = await props.api.put("/trabajos/"+id,response.data);
      if (response.error) throw new Error("Error al actualizar trabajo");
      getData();
      resetFilter();
      return true;
    } catch (e) {
        return false; 
      }
  }
  const changeStateFinal = async (id,state,stateNote) => {
    try{
      var { data, error } = await props.api.get("/trabajos/"+id);
      if (error) throw new Error("Error al obtener trabajo");
      
      data.estado = state;
      data.observaciones = stateNote;
      data.historial.push({estado: state.nombre, fecha: functions.dateToString(new Date()),encargado: props.auth.getUserName(), nota: stateNote });
      var response = await props.api.put("/trabajos/"+id,data);
      if (response.error) throw new Error("Error al obtener trabajo");
      resetFilter();
      getData();
      return true;
    } catch (e) { 
      return false;
     }
  }
  const resetFilter = () => {
    setSelectedStoreBranch('');
    setFirstSelectedCategory('');
    setSelectedCategory('');
    setSelectedObject('');
    setSelectedBrand('');
    setSearch('');
    setIdSearch('');
    setRutSearch('');
    setDocumentSearch('');
    setNameSearch('');
    setFilterSwitch(!filterSwitch);
  }
  const updateWorkValues = async (id,additionalFee,initialWeight,finalWeight, description) => {
    try {
      var response = await props.api.get("/trabajos/" + id);
      if (response.error) throw new Error(`Error al obtener trabajo ${id}`);
      var work = response.data;
    }
    catch (e){console.log(e)}
    if (work) try {
      work.costo_adicional = additionalFee;
      work.peso_inicial = initialWeight;
      work.peso_final = finalWeight;
      work.descripcion_costo_adicional = description;
      var response = await props.api.put("/trabajos/" + id,work);
      if (response.error) throw new Error(`Error al actualizar trabajo ${id}`);
      getData();
      return(true);
    }
    catch (e) { return false;}
  }
  const changeMultipleState = async (ids,state,employee,stateNote,final,paymentBill=null, costs=null) => {
    var works = []
    var errGet = []
    var errUpd = []

    var succGet = 0;
    var succUpd = 0;
    /*
    // Error test
    ids.push(-99);
    works.push({id: -4777,estado:state, historial:[]})
    */
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      try {
        var response = await props.api.get("/trabajos/" + id);
        if (response.error) throw new Error(`Error al obtener trabajo ${id}`);
        works.push(response.data);
        succGet += 1;
      }
      catch (e) {
        errGet.push(id);
      }

    }

    var work = works[0];
    var consolidated_payments = work.documento?.abono_consolidado
    var abonos = costs.map((e)=> {
      return {trabajo: e.folio ,valor_consolidado: e.abono}
    }).filter((e) => {return(e.valor_consolidado > 0)})
    
    for (let e of abonos) {
      consolidated_payments.push(e);
    }
    console.log(consolidated_payments)

    if(abonos.length > 0 && final){
      response = await props.api.put("/documentos/" + work.documento.id, {abono_consolidado: consolidated_payments});
      if (response.error) throw new Error(`Error al actualizar el documento ${work.documento.id}`);
    }

    //create cambio-de-estado
    var user = final ? props.auth.getUserName() : employee
    console.log("user", user)
    response = await props.api.post("/cambios-de-estados", {empleado: user});
    if (response.error) throw new Error("Error al crear el cambio de estado");
    var stateChange = response.data

    
    var succSentWorks = [];
    for (let i = 0; i < works.length; i++) {
      const work = works[i];
      work.estado = state;
      if (final) {
        work.observaciones = stateNote;
        work.boleta_saldo = paymentBill;
      }
      work.historial.push({ estado: state.nombre, fecha: functions.dateToString(new Date()), encargado: final ? props.auth.getUserName() : employee, nota: stateNote, cambio_de_estado: stateChange});
      try {
        response = await props.api.put("/trabajos/" + work.id, work);
        if (response.error) throw new Error(`Error al actualizar trabajo ${work.id}`);
        succUpd += 1;
        succSentWorks.push(work);
      }
      catch (e) {
        errUpd.push(work.id);
      }
    }
    setSentWorks(succSentWorks);
    resetFilter();
    getData();
    if (errGet.length === 0 && errUpd.length === 0) return false

    // Si devuelve listas con solo un -1, la operación completa fracasó
    errGet = succGet === 0 ? [-1] : errGet;
    errUpd = succUpd === 0 ? [-1] : errUpd;

    return {errGet:errGet,errUpd:errUpd}
  }
  
  const handleSubmit = () => {
    var filteredData = allData;

    if (selectedStoreBranch && selectedStoreBranch !== "TODAS") {
      filteredData = filteredData.filter(
        function (d) {
          console.log(d.sucursal?.nombre, selectedStoreBranch)
          return d.sucursal && d.sucursal?.nombre.toUpperCase() === selectedStoreBranch.nombre.toUpperCase()
        }
      )
    }

    if (selectedCategory && selectedCategory !== "TODAS") {
      var objects = selectedCategory.objetos.map(o => { return o.nombre });
      var brands = selectedCategory.marcas.map(o => { return o.nombre });
     
      filteredData = filteredData.filter(
        function (d) {
          
          var foundBrand = !selectedBrand || selectedBrand === "TODAS" ? brands.includes(d.marca) : d.marca === selectedBrand;
          var foundObject = !selectedObject || selectedObject === "TODOS" ? objects.includes(d.objeto) : d.objeto === selectedObject;
          var foundState = !selectedState || selectedState === "TODOS" ? true : d.estado.id === selectedState.id;

          return (foundBrand && foundObject && foundState);
        }
      );
    } else{
      filteredData = filteredData.filter(
        function (d) {
          var foundState = !selectedState || selectedState === "TODOS" ? true : d.estado.id === selectedState.id;
          var foundFirstCategory = filterCategories().filter(category => category.nombre === d.categoria).length > 0;
          return (foundState & foundFirstCategory);
        }
      );
    }
      var ids = []
      filteredData.forEach(d => {
        ids.push(d.id);
      });
      var filteredRows = allRows.filter(
        function (r) {
          return (ids.includes(r.id))
        }
      )
      setFilterSwitch(!filterSwitch);
      if (advancedSearchOpen) filteredRows = filterList(filteredRows, search, rutSearch, idSearch, documentSearch, nameSearch);
      setRows(filteredRows);
    
  } 
  const getAuxData = async () => {
    var response = await props.api.get("/estados?_sort=nivel:ASC", true);
    if (response.error) throw new Error("Error al obtener estados");
    setStates(response.data);
    setIncomingStates(response.data.filter(function (d) { return userIncomingStates.includes(d.nivel) }));
    setOutgoingStates(response.data.filter(function (d) { return userOutgoingStates.includes(d.nivel) }));
    response = await props.api.get("/empleados", true)
    if (response.error) throw new Error("Error al obtener los empleados");
    setStaff(response.data);
    response = await props.api.get("/categorias", true)
    if (response.error) throw new Error("Error al obtener las categorías");
    response.data.forEach(d => {
      d.nombre = d.nombre.toUpperCase();
      d.objetos.forEach(o => {
        o.nombre = o.nombre.toUpperCase();
      })
      d.marcas.forEach(o => {
        o.nombre = o.nombre.toUpperCase();
      })
    });
    setCategories(response.data);
    response = await props.api.get("/estado")
    if (response.error) throw Error(response.error);
    setInitialState(response.data.estado_inicial);

    response = await props.api.get("/primeras-categorias", true);
    if (response.error) throw new Error("Error al obtener las primeras categorías");
    response.data.forEach(d => {
      d.nombre = d.nombre.toUpperCase();
    });
    setFirstCategories(response.data);

    response = await props.api.get("/sucursales", true);
    if (response.error) throw new Error("Error al obtener las sucursales");
    response.data.forEach(d => {
      d.nombre = d.nombre.toUpperCase();
    });
    setStoreBranches(response.data);
  }
  useEffect(() => {
    getData();
    getAuxData();
  },
  []);
  
  return (
    <div>
      <form className={classes.root} onSubmit={handleSubmit} autoComplete="off">
      <Paper className={classes.paper}>
          <Grid fullWidth container spacing={1} alignItems='center'>
            <Grid container spacing={1} alignItems='center'>
            <Grid item xs={2}>
                <FormControl variant="filled" fullWidth>

                  <InputLabel id="select-label">Selecione sucursal</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-label"
                    id="select"
                    value={selectedStoreBranch}
                    onChange={(e) => {
                      setSelectedStoreBranch(e.target.value);
                    }}

                  >
                    <MenuItem value={"TODAS"}> {"TODAS"} </MenuItem>
                    {storeBranches && storeBranches.map(s => {
                      return (
                        <MenuItem value={s}> {s.nombre} </MenuItem>
                      )
                    })}
                  </Select>


                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl variant="filled" fullWidth>

                  <InputLabel id="select-label">Selecione estado</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-label"
                    id="select"
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                    }}

                  >
                    <MenuItem value={"TODOS"}> {"TODOS"} </MenuItem>
                    {incomingStates && incomingStates.map(s => {
                      return (
                        <MenuItem value={s}> {s.nombre} </MenuItem>
                      )
                    })}
                  </Select>


                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl variant="filled" fullWidth>

                  <InputLabel id="select-label">Selecione primera categoría</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-label"
                    id="select"
                    value={selectedFirstCategory}
                    onChange={(e) => {
                      setSelectedBrand('');
                      setSelectedObject('');
                      setSelectedCategory('');
                      setFirstSelectedCategory(e.target.value);
                    }}
                  >
                    <MenuItem value={"TODAS"}> {"TODAS"} </MenuItem>
                    {firstCategories && firstCategories.map(s => {
                      return (
                        <MenuItem value={s}> {s.nombre} </MenuItem>
                      )
                    })}
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl variant="filled" fullWidth>

                  <InputLabel id="select-label">Selecione categoría</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-label"
                    id="select"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedBrand('');
                      setSelectedObject('');
                      setSelectedCategory(e.target.value);
                    }}

                  >
                    <MenuItem value={"TODAS"}> {"TODAS"} </MenuItem>
                    {categories && filterCategories().map(s => {
                      return (
                        <MenuItem value={s}> {s.nombre} </MenuItem>
                      )
                    })}
                  </Select>


                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl variant="filled" fullWidth disabled={!selectedCategory || selectedCategory === "TODAS"}>
                  <InputLabel id="select-label">Seleccione objeto</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-label"
                    id="select"
                    value={selectedObject}
                    onChange={(e) => { setSelectedObject(e.target.value) }}
                  >
                    <MenuItem value={"TODOS"}> {"TODOS"} </MenuItem>
                    {selectedCategory && selectedCategory !== "TODAS" && selectedCategory.objetos.map(s => {
                      return (
                        <MenuItem value={s.nombre}> {s.nombre} </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={2}>
                <FormControl variant="filled" fullWidth disabled={!selectedCategory || selectedCategory === "TODAS"}>
                  <InputLabel id="select-label">Seleccione marca</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-label"
                    id="select"
                    value={selectedBrand}
                    onChange={(e) => { setSelectedBrand(e.target.value) }}
                  >
                    <MenuItem value={"TODAS"}> {"TODAS"} </MenuItem>
                    {selectedCategory && selectedCategory !== "TODAS" && selectedCategory.marcas.map(s => {
                      return (
                        <MenuItem value={s.nombre}> {s.nombre} </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            
           
                <Grid item xs={12}>
                  <Collapse in={advancedSearchOpen}>
                <Grid item xs={12}>

                  {props.auth.seesClient() === 1 &&
                    <Grid container spacing={1} >
                      <Grid item xs={2}>
                        <TextField fullWidth
                          label="Nro. de seguimiento"
                          id="search-doc"
                          className={classes.textField}
                          margin="dense"
                          variant="filled"
                          value={documentSearch}
                          onChange={(e) => {
                            setDocumentSearch(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField fullWidth
                          label="Folio"
                          id="search-id"
                          className={classes.textField}
                          margin="dense"
                          variant="filled"
                          value={idSearch}
                          onChange={(e) => {
                            setIdSearch(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField fullWidth
                          label="Nombre"
                          id="search-name"
                          className={classes.textField}
                          margin="dense"
                          variant="filled"
                          value={nameSearch}
                          onChange={(e) => {
                            setNameSearch(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField fullWidth
                          label="Rut"
                          id="search-rut"
                          className={classes.textField}
                          margin="dense"
                          variant="filled"
                          value={rutSearch}
                          onChange={(e) => {
                            setRutSearch(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField fullWidth
                          label="Objeto, marca o trabajo"
                          id="search-term"
                          className={classes.textField}
                          margin="dense"
                          variant="filled"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>

                  }
                  {props.auth.seesClient() === 0 &&
                    <Grid container spacing={1}>

                      <Grid item xs={3}>
                        <TextField fullWidth
                          label="Folio"
                          id="search-id"
                          className={classes.textField}
                          margin="dense"
                          variant="filled"
                          value={idSearch}
                          onChange={(e) => {
                            setIdSearch(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={9}>
                        <TextField fullWidth
                          label="Objeto, marca o trabajo"
                          id="search-term"
                          className={classes.textField}
                          margin="dense"
                          variant="filled"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>

                  }
                </Grid>

                  </Collapse>
                </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Switch checked={advancedSearchOpen} onChange={(event)=>setAdvancedSearchOpen(event.target.checked)} />}
                label="Búsqueda avanzada"
              />
            </Grid>
          <Grid item xs></Grid>
            <Grid item xs={1}>
              <Button fullWidth type="submit" >Filtrar</Button>
            </Grid>
        
        <Grid item xs={12}>
              <JobTable 
                rows={rows}
                states={states}
                initialState={initialState}
                filterSwitch={filterSwitch}
                outgoingStates={outgoingStates}
                staff={staff}
                allData={allData}
                sentWorks={sentWorks}
                seesHistory={props.auth.seesHistory()===1}
                seesClient={props.auth.seesClient()===1}
                downloadsData={props.auth.downloadsData()===1}
                changeState={changeState}
                changeStateFinal={changeStateFinal}
                changeMultipleState={changeMultipleState}
                updateWorkValues={updateWorkValues}
                auth={props.auth}
                api={props.api}
              />
      </Grid>
      </Grid>
      </Paper>
      </form>
    </div>
  );
}
