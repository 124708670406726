import React, { useState } from 'react';

/* Materia UI */
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import PersonIcon from '@material-ui/icons/Person';
/* END Materia UI */

/* Services */

/* END Services */



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        height: "400px"
    }
}));

export default function Login(props) {
    const classes = useStyles();
    console.log(props)
    const [user, setUser] = useState()
    const [password, setPassword] = useState()
    const [errorMsg, setErrorMsg] = useState(null)
    const login = async function (user, password) {
        try {
            var { data, error } = await props.auth.login(user, password)
            if (error) {
                setErrorMsg(error)
            }
            if (data === true) {
                props.history.push("/inicio")
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        login(user, password)
    }

    return (
        <Container className={classes.container} component="main" maxWidth="md" >
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.avatar}>
                    <PersonIcon fontSize="large" />
                </div>
                <Typography component="h1" variant="h5">
                    Ingresar
        </Typography>
                <br />
                {errorMsg &&
                    <Alert variant="outlined" severity="error" style={{ width: "100%" }} onClose={() => { setErrorMsg(null) }}>{errorMsg} </Alert>
                }
                <form onSubmit={handleSubmit}>
                <FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => setUser(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Ingresar
                    </Button>
                    <Grid container style={{ width: "400px" }}>
                        <Grid item style={{ width: "100%" }}>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </FormControl>
                </form>
            </div>
            <Box mt={8}>
                {/*<Copyright />*/}
            </Box>
        </Container>
    );
}