import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
    return (
        <div>
            <Dialog
                open={props.open ? props.open : false}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.text ? props.text.split("\n").map((t, i)=><p key={i}>{t}</p>) : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.buttons && props.buttons.map((b, i)=>{
                        return(
                            <Button key={i} {...b.props}>
                                {b.text}
                            </Button>
                        )
                    })}
                </DialogActions>
            </Dialog>
        </div>
    );
}