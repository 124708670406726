import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBarClass: {
      background: 'black',
  },
  innerFrame: {
      display: 'flex',
      width: '90%',
      marginLeft: '5%',
      justifyContent: 'space-between'
  }
}));

export default function NavBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBarClass}>
         
        <Toolbar>
        <div className={classes.innerFrame}>
          <a href='https://www.labrin.cl/'>
            <img src='./logo.webp' ></img>
          </a>
            <Button href='https://www.labrin.cl/' color="inherit">Volver</Button>
        </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}