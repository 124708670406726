import axios from 'axios';
import config from "./config"

function strToList(str){
    return str.split(",").map(d=>(parseInt(d)))
}

function saveToLS(key, data) {
    var encrypted_data = btoa(data)
    localStorage.setItem(key, encrypted_data)
}

function loadFromLS(key) {
    var data = localStorage.getItem(key)
    var decrypted_data = null
    try{
        decrypted_data = data ? atob(data) : null
    }
    catch(e){
        decrypted_data = null
    }
    return decrypted_data
}

class Auth {

    token_key = "token"
    accessLevels = {
        in: [],
        out: []
    }


    constructor(){
        let token = loadFromLS(this.token_key)
        let user = loadFromLS("user")
        let userId = loadFromLS("userId")
        let accessLevels = JSON.parse(loadFromLS("accessLevels"))
        let privileges = JSON.parse(loadFromLS("privileges"))
        this.token = token ? token : ""
        this.user = user ? user : null
        this.userId = userId ? userId : null
        this.accessLevels = accessLevels ? accessLevels : {in: [],out: []}
        this.privileges = privileges ? privileges : [0, 0, 0, 0]
    }

    async login(user, password){
        try{
            var {data} = await axios.post(config.API + '/auth/local', {
                identifier: user,
                password: password,
            });
            if(data?.jwt){
                saveToLS(this.token_key, data.jwt)
                saveToLS("user", data.user.username)
                saveToLS("userId", data.user.id)
                this.token = data.jwt
                this.user = data.user.username
                this.userId = data.user.id
                this.privileges = [
                    data.user.cargo.crea_trabajos ? 1 : 0,
                    data.user.cargo.ve_cliente ? 1: 0,
                    data.user.cargo.ve_historial ? 1 : 0,
                    data.user.cargo.descarga_datos ? 1 : 0,
                ]
                this.accessLevels["in"] = strToList(data.user.cargo.recibe_niveles)
                this.accessLevels["out"] = strToList(data.user.cargo.entrega_niveles)
                saveToLS("accessLevels", JSON.stringify(this.accessLevels))
                saveToLS("privileges", JSON.stringify(this.privileges))


                return {data: true}
            }
            else{ 
                throw Error("Error")
            }
        }
        catch(e){
            if (e?.response?.status === 400) return { error: "Identificador o contraseña incorrecta." }
            else return { error: "Error interno del servidor"}
            
        }

        
    }

    isLogedIn(){
        return this.token ? true : false
    }

    logout(){
        document.getElementById('logoutButton').click();
    }

    logoutButton(){
        this.token = null
        localStorage.removeItem(this.token_key)
        localStorage.removeItem("user")
        localStorage.removeItem("userId")
        localStorage.removeItem("accessLevels")
        localStorage.removeItem("privileges")
    }

    getToken(){
        return this.token
    }

    getUserName(){
        return this.user
    }

    getUserId() {
        return this.userId
    }

    getAccessLevelsIn(){
        return this.accessLevels.in
    }

    getAccessLevelsOut() {
        return this.accessLevels.out
    }

    createsWork(){
        return this.privileges[0]
    }

    seesClient() {
        return this.privileges[1]
    }

    seesHistory() {
        return this.privileges[2]
    }

    downloadsData(){
        return this.privileges[3]
    }
}


export default Auth;