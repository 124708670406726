import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';

import ClientForm from "components/ClientForm"
import WorkForm from "components/WorkForm"
import AlertDialog from "components/AlertDialog"

import generatePDF from "_services/generatePDF"
import functions from "_services/functions"



const useStyles = makeStyles((theme) => ({
    input:{
        width: "100%"
    },
    subTitle:{
        margin: "20px 0px 30px"
    }

}));


function FormSection(props){
    const classes = useStyles();
    return(
        <React.Fragment>
            <Typography className={classes.subTitle} variant="h5" component="h2">
                {props.text}
                <hr />
            </Typography>
        </React.Fragment>
    )
}

function dateToStr(d){
    var month = d.getMonth() + 1
    month = month.toString()
    month = month.length===1 ? "0" + month : month
    var day = d.getDate().toString()
    day = day.length===1 ? "0" + day : day
    var new_date = d.getFullYear() + "-" + month + "-" + day
    return new_date
}


const rut_pattern = new RegExp("(^$|^[0-9]{1,}([0-9]|k|K)?$)")
const initialState = {
    works: 0,
    total_value: 0,
    works_ids: [],
}

class AddWork extends React.Component {

    storeBranches = []
    firstCategories = []
    categories = []
    default_state = {}
    workForms = {}
    total_value = 0

    constructor(props){
        super(props)
        this.state = {
            works:0,
            total_value: 0,
            works_ids: [],
            alertDialog: false,
            errorDialog: false,
            successDialog: false,
            rutError: false,
        }
    }

    // Load dropdown data and default state
    async componentDidMount() {
        try {
            if (this.storeBranches.length === 0) {
                var { data, error } = await this.props.api.get("/sucursales")
                if (error) throw Error(error);
                if(data.length === 0){
                    throw Error("No existen sucursales en la base de datos")
                }
                this.storeBranches = this.storeBranches.concat(data)
                for(var sb of this.storeBranches){
                    sb.nombre = sb.nombre.toUpperCase()
                }
            }
            if (this.firstCategories.length === 0) {
                var { data, error } = await this.props.api.get("/primeras-categorias")
                if (error) throw Error(error);
                if(data.length === 0){
                    throw Error("No existen primeras categorias en la base de datos")
                }
                this.firstCategories = this.firstCategories.concat(data)
                for(var c of this.firstCategories){
                    c.nombre = c.nombre.toUpperCase()
                }
            }
            if (this.categories.length === 0) {
                var { data, error } = await this.props.api.get("/categorias")
                if (error) throw Error(error);
                if(data.length === 0){
                    throw Error("No existen categorias en la base de datos")
                }
                this.categories = this.categories.concat(data)
                for(var c of this.categories){
                    c.nombre = c.nombre.toUpperCase()
                    c.objetos.map((e) => (e.nombre = e.nombre.toUpperCase()))
                    c.marcas.map((e) => (e.nombre = e.nombre.toUpperCase()))
                }
            }
            if (Object.keys(this.default_state).length === 0) {
                var { data, error } = await this.props.api.get("/estado")
                if (error) throw Error(error);
                this.dafault_state = Object.assign(this.default_state, data.estado_inicial);
            }
        }
        catch (e) {
            console.log(e)
            alert("Ha ocurrido un error al obtener información de la base de datos.")
            this.setState({ error: true})
        }
    }

    handleWorkForm(id, form){
        this.workForms[id] = form
    }

    updateTotalValue(id, value){
        var total_value = 0
        if (!this.workForms){
            return
        }
        for(var k of Object.keys(this.workForms)){
            if (!this.workForms[k]["input-value"] || parseInt(k) === parseInt(id)){
                continue
            }
            total_value += parseInt(this.workForms[k]["input-value"])
        }
        total_value = total_value + parseInt(value)
        this.setState({total_value: total_value})
    }

    deleteWorkForm(id) {
        delete this.workForms[id]
        var index = this.state.works_ids.indexOf(id)
        var new_works_ids = this.state.works_ids
        new_works_ids.splice(index, 1)
        this.setState({works_ids: new_works_ids})
        this.updateTotalValue(id, 0)
    }

    handleChange(e){
        var name = e.target.id
        var value = e.target.value
        var positionOffset = 0 
        // If input-rut: clean
        if(name === "input-rut"){
            var originalLength = value.length
            value = value.replace("-", "");
            if (!rut_pattern.test(value)) {
                value = this.state[name] ? this.state[name] : ""
            }
            else if (value.length > 1) {
                value = [value.slice(0, value.length - 1), "-", value.slice(value.length - 1)].join('');
            }
            positionOffset = value.length - originalLength
            
        }
        if(typeof value === "string"){
            value = value.toUpperCase()
        }
        this.setState({[name]: value, [name+"-error"]: null})

        // Move cursor to correct position. Position Offset handles rut length difference
        try{
            if (typeof value === "string" && e.target.selectionStart && e.target.inputPosition) {
                window.requestAnimationFrame(() => {
                    e.target.selectionStart = e.target.inputPosition + positionOffset
                    e.target.selectionEnd = e.target.inputPosition + positionOffset
                })
            }
        }catch(e){console.log(e)}

    }

    // If exist load client
    async getClient(){
        this.setState({"showClientForm": false, "rutError": false})
        if(!this.state["input-rut"]){
            return false
        }
        var rut_n_digit_list = this.state["input-rut"].split("-")
        if(rut_n_digit_list.length != 2 || !functions.rutIsValid(rut_n_digit_list[0], rut_n_digit_list[1])){
            this.setState({"rutError": true})
            return false
        }
        var new_state = this.state
        var rut = this.state["input-rut"]
        new_state = this.cleanState(new_state)
        //clean current state
        new_state["input-rut"] = rut
        try{
            var { data, error } = await this.props.api.get("/clientes?rut=" + this.state["input-rut"])
            if(error){
                throw Error(error)
            }
            if (data.length === 0) {
                new_state["showClientForm"] = true
                this.setState(new_state)
                return true
            }
            var client = data[0]

            new_state["input-name"] = client.nombre
            new_state["input-surname"] = client.apellido
            new_state["input-phone"] = client.telefono
            new_state["input-email"] = client.email
            new_state["clientData"] = client
            new_state["showClientForm"] = true

            this.setState(new_state)

        }catch(e){console.log(e)}
    }

    // Set state to default values
    cleanState(){
        // Clean workforms
        this.workForms = {}

        var new_state = this.state
        
        // Clean current state
        for (let k in new_state) {
            new_state[k] = ""
        }
        // Load default values
        for (let k in initialState) {
            if (Array.isArray(initialState[k])){
                new_state[k] = []
            }
            else{
                new_state[k] = initialState[k]
            }   
        }
        return new_state
    }

    validation(fields, data, total_value) {
        var errors = {}
        for (var f in fields) {
            if (fields[f].includes("notNull")) {
                var name_in_state = "input-" + f
                if (!data[name_in_state]) {
                    errors[name_in_state + "-error"] = true
                }
            }
            if (fields[f].includes("leqTotalValue")) {
                var name_in_state = "input-" + f
                if (data[name_in_state] && !(parseInt(data[name_in_state]) <= parseInt(total_value))) {
                    errors[name_in_state + "-error"] = true
                }
            }
            if (fields[f].includes("customNotNull")) {
                var name_in_state = "input-" + f
                if (name_in_state === "input-payment") {
                    if (data["input-payment-bill"] && !data[name_in_state]){
                        errors[name_in_state + "-error"] = true
                    }
                }
                if (name_in_state === "input-payment-bill") {
                    if (data["input-payment"] && !data[name_in_state]) {
                        errors[name_in_state + "-error"] = true
                    }
                }
            }
        }
        var nErrors = Object.keys(errors).length
        if (nErrors === 0) {
            return true
        }
        return errors
    }

    formValidation(){
        var clientFields = {
            name: ["notNull"],
            surname: ["notNull"],
            phone: ["notNull"],
            email: [],
        }
        var workFields = {
            ["store-branch"]: ["notNull"],
            ["first-categorie"]: ["notNull"],
            categorie: ["notNull"],
            object: ["notNull"],
            brand: ["notNull"],
            work: ["notNull"],
            delivery: ["notNull"],
            value: ["notNull"],
            payment: ["customNotNull", "leqTotalValue"],
            ["payment-bill"]: ["customNotNull"]

        }
        var errors = {}
        var client_validation = this.validation(clientFields, this.state)
        if (client_validation !== true) {
            this.setState(client_validation)
            errors["client"] = client_validation
        }
        var work_validation;
        var forms = Object.keys(this.workForms)
        var total_value = 0
        for(var k of forms){
            total_value += parseInt(this.workForms[k]["input-value"])
        }
        for(var k of forms){
            work_validation = this.validation(workFields, this.workForms[k], total_value)
            if(work_validation === true){
                continue
            }
            else{
                errors[k] = work_validation
            }
        }

        if (Object.keys(errors).length > 0){
            this.setState({formErrors: errors})
            return false
        }
        return true
    }

    async save(){
        var errors = []
        var validation = this.formValidation()
        if(!validation){
            return false
        }
        var client_data = {
            rut: this.state["input-rut"],
            nombre: this.state["input-name"],
            apellido: this.state["input-surname"],
            telefono: this.state["input-phone"],
            email: this.state["input-email"]
        }
        if(!this.state.clientData){
            var { data, error } = await this.props.api.post("/clientes", client_data)
            if(error){
                errors.push("Error al crear el cliente.")
            }
            else{
                this.setState({clientData: data})
            }
        }
        else if(
            !(
                this.state.clientData.nombre === client_data.nombre &&
                this.state.clientData.apellido === client_data.apellido &&
                this.state.clientData.telefono === client_data.telefono &&
                this.state.clientData.email === client_data.email 
            )
        ){
            var { data, error} = await this.props.api.put("/clientes/"+this.state.clientData.id, client_data)
            if(error){
                errors.push("Error al actualizar el cliente.")
            }

        }
        else if (Object.keys(this.workForms).length===0){
            this.setState({errorDialog: true, errorDialogText: "No hay cambios"})
            return true
        }
        var work_return_data = null
        if(this.state.works){
            if (this.state.clientData.id){
                var works = []
                var payment = this.workForms[1]["input-payment"] || null
                var paymentBill = this.workForms[1]["input-payment-bill"] || null
                
                for(var k in this.workForms){
                    let work = this.workForms[k]
                    let storeBranch = this.storeBranches.filter(sb => sb.nombre === work["input-store-branch"])[0];
                    let work_data = {
                        sucursal: storeBranch,
                        categoria: work["input-categorie"],
                        objeto: work["input-object"],
                        marca: work["input-brand"],
                        trabajo: work["input-work"],
                        entrega: dateToStr(work["input-delivery"]),
                        valor: work["input-value"],
                        cliente: this.state.clientData.id,
                        estado: this.default_state.id,
                        historial: [{
                            estado: this.default_state.nombre,
                            nota: work["input-note"] ? work["input-note"] : "",
                            fecha: dateToStr(new Date()),
                            encargado: this.props.auth.getUserName().toUpperCase(),
                        }]
                    }
                    works.push(work_data)
                }
                var { data, error } = await this.props.api.post("/trabajos/bulk", {list: works, payment:payment, paymentBill:paymentBill})
                if (error) { errors.push("Error al crear el trabajo.")}
                work_return_data = data
            } 
            else {
                errors.push("Error al crear el trabajo.")
            }
        }
        
        if(errors.length > 0){
            this.setState({ errorDialog: true, errorDialogText: errors.join("\n") })
        }
        else{
            var message = ""
            var disableGenerator = true
            if (work_return_data){
                message = "Número de seguimiento: " + work_return_data.identificador.toString() + work_return_data.id.toString() + " \n"
                message += "Folios: "
                for (let w of work_return_data.trabajos){
                    message += w.id + "  "
                }
                disableGenerator = false
            }
            var new_state = this.state
            new_state = this.cleanState(new_state)
            new_state = { ...{ successDialog: true, successDialogText: message, workReturnData: work_return_data, disableGenerator: disableGenerator }}
            this.setState(new_state)
        }

    }

    PDF(){
        try {
            if(!this.state.workReturnData){
                throw Error("no work data")
            }
            var data = this.state.workReturnData.trabajos
            var cliente = this.state.workReturnData.cliente
            for(let w of data){
                generatePDF({
                    id: w.id,
                    objeto: w.objeto,
                    marca: w.marca,
                    telefono: cliente.telefono,
                    trabajo: w.trabajo,
                    categoria: w.categoria,
                    valor: w.valor,
                    abono: w.abono ? w.abono : "0",
                    nombre: cliente.nombre + " " + cliente.apellido,
                    observacion: w.historial[0]["nota"],
                    recepcion: functions.dateToLocalString(w.created_at.split("T")[0]),
                    entrega: functions.dateToLocalString(w.entrega),
                    seguimiento: this.state.workReturnData.identificador + this.state.workReturnData.id,
                    documento: this.state.workReturnData,
                }, this.props.api)
            }
            
        }
        catch (e){console.log(e)}
    }


    render(){
        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField
                                    onChange={(e) => this.handleChange(e)}
                                    value={this.state["input-rut"]}
                                    style={{width: "100%"}}
                                    id="input-rut"
                                    label="RUT"
                                    variant="outlined"
                                    error={this.state["rutError"]}
                                    helperText={this.state["rutError"] ? "RUT inválido" : ""}
                                    onInput={((e) => { e.target.inputPosition = e.target.selectionStart })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{padding: "7px", height: "100%"}}>
                                <Button onClick={()=>this.getClient()} variant="contained" color="primary" style={{height: "100%"}} disabled={this.state.error}>Comenzar</Button>
                                </div>
                            </Grid>
                        </Grid>
                        { this.state.showClientForm &&
                        <React.Fragment>
                            <FormSection text="Información del cliente" />
                            <ClientForm
                                onChange={(e) => this.handleChange(e)}
                                state={this.state}
                            />
                        <FormSection text="Trabajo" />
                        { this.state.works_ids &&
                            this.state.works_ids.map((i)=>{
                                var errors = this.state.formErrors && this.state.formErrors[i] ? this.state.formErrors[i] : {}
                                return(
                                    <div key={i}>
                                        <WorkForm index={i} onChange={this.handleWorkForm.bind(this)} delete={this.deleteWorkForm.bind(this)} updateTotalValue={this.updateTotalValue.bind(this)} storeBranches={this.storeBranches} categories={this.categories} firstCategories={this.firstCategories} state={this.state} errors={errors} />
                                        <hr style={{margin: "25px 0px"}}/>
                                    </div>
                                )
                            })
                        }
                        <Grid container spacing={1} style={{marginBottom: "10px"}}>
                            <Grid item xs={12} container={true} alignItems="center" justify="center">
                                <Fab 
                                    color={"primary"} 
                                    aria-label="add" 
                                    onClick={()=>{
                                        var new_works_ids = this.state.works_ids
                                        new_works_ids.push(this.state.works + 1)
                                        this.setState({
                                            works: this.state.works + 1,
                                            works_ids: new_works_ids
                                        })
                                    }}
                                >
                                        <AddIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                        </React.Fragment>
                        }
                    </CardContent>
                    <CardActions>
                        { this.state.showClientForm && this.state.total_value > 0 && <b style={{width: "30%", fontSize: "20px"}}>{"Total: " + functions.amountFormat(this.state.total_value)}</b> }
                        { this.state.showClientForm &&
                        <Grid container alignItems="flex-end" justify="flex-end">
                            <Button size="small" onClick={() => this.formValidation() ? this.setState({ confirmationDialog: true }) : this.setState({ confirmationDialog: false })}>Guardar</Button>
                        </Grid> 
                        }
                    </CardActions>
                </Card>

                <AlertDialog
                    open={this.state.confirmationDialog}
                    handleClose={() => this.setState({confirmationDialog: false})}
                    title="¿Guardar los datos?"
                    text={this.state.works ? "Se actualizará la información del cliente y se guardarán los trabajos" : "Se actualizará la información del cliente"}
                    buttons={
                        [
                            { text: "Cancelar", props: { onClick: () => this.setState({ confirmationDialog: false }) } },
                            { text: "Aceptar", props: { onClick: () => {this.setState({ confirmationDialog: false }); this.save()} } },
                        ]
                    }
                />

                <AlertDialog
                    open={this.state.errorDialog}
                    handleClose={() => this.setState({ errorDialog: false })}
                    title="Error"
                    text={this.state.errorDialogText}
                    buttons={
                        [
                            { text: "OK", props: { onClick: () => this.setState({ errorDialog: false }) } },
                        ]
                    }
                />

                <AlertDialog
                    open={this.state.successDialog}
                    handleClose={() => this.setState({ successDialog: false })}
                    title="Datos guardado con éxito!"
                    text={this.state.successDialogText}
                    buttons={
                        [
                            { text: "ver trabajos", props: { onClick: () => { this.setState({ successDialog: false }); this.props.history.push("/trabajos")} } },
                            { text: "agregar nuevo trabajo", props: { onClick: () => this.setState({ successDialog: false }) } },
                            { text: "generar comprobante", props: { onClick: () => this.PDF(), disabled: this.state.disableGenerator}},
                        ]
                    }
                />


                
            </React.Fragment>
        );
    }
}

export default AddWork;