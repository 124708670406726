const functions = {}

functions["dateToString"] = function(d) {
    var month = d.getMonth() + 1
    month = month.toString()
    month = month.length === 1 ? "0" + month : month
    var day = d.getDate().toString()
    day = day.length === 1 ? "0" + day : day
    var new_date = d.getFullYear() + "-" + month + "-" + day
    return new_date
}

functions["dateToLocalString"] = function(d){
    d = d.split("-")
    var date = new Date(d[0], d[1] - 1, d[2]);
    var year = date.getFullYear()
    var month = (date.getMonth() + 1).toString()
    month = month.length === 1 ? "0" + month : month
    var day = (date.getDate()).toString()
    day = day.length === 1 ? "0" + day : day
    return day + "-" + month + "-" + year
}

functions["datetimeToLocalString"] = function(d){
    var date = new Date(d);
    var year = date.getFullYear()
    var month = (date.getMonth() + 1).toString()
    month = month.length === 1 ? "0" + month : month
    var day = (date.getDate()).toString()
    day = day.length === 1 ? "0" + day : day
    var hours = date.getHours().toString()
    hours = hours.length === 1 ? "0" + hours : hours
    var minutes = date.getMinutes().toString()
    minutes = minutes.length === 1 ? "0" + minutes : minutes
    return day + "-" + month + "-" + year + " " + hours + ":" + minutes 
}

function getValidatedVerificationDigit(verificationDigit) {
    var verificationDigitNumber;
    verificationDigit = verificationDigit.toUpperCase()
    if (verificationDigit.length != 1) {
        return [false, 0];
    }
    if (verificationDigit == "K") {
        verificationDigitNumber = 10;
    } else {
        verificationDigitNumber = parseInt(verificationDigit, 10);
    }

    return [verificationDigitNumber >= 0 && verificationDigitNumber <= 10, verificationDigitNumber];
}

functions["rutIsValid"] = function(digits, verificationDigit){
    const digitsArray = digits.split("");
    const multipliers = [2,3,4,5,6,7];

    var [isValid, verificationDigitNumber] = getValidatedVerificationDigit(verificationDigit);

    if (!isValid || !(digits.length == 8 || digits.length == 7)){
        return false;
    }

    var sumDigits = 0;
    var index = 0;
    for (let val of digitsArray.reverse()){
        if (index == 6) {
            index = 0;
        }
        sumDigits +=  (multipliers[index] * parseInt(val, 10));
        index ++;
    }

    var expectedVerificationDigit = 11 - (sumDigits % 11);
    if (expectedVerificationDigit == 11) {
        expectedVerificationDigit = 0;
    } 

    return expectedVerificationDigit == verificationDigitNumber;
}

functions["amountFormat"] = function(ammount) {
    var pos = 0
    ammount = ammount.toString()
    ammount = ammount.split("").reverse().join("");
    var new_value = ""
    for (var num of ammount) {
        if (pos === 3) {
            new_value = "." + new_value
            pos = 0
        }
        new_value = num + new_value
        pos += 1
    }
    return new_value
}

export default functions