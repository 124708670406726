import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    input: {
        width: "100%"
    }

}));

function CustomTextField(props){
    const classes = useStyles();
    return (<TextField className={classes.input} variant="outlined" {...props} />)
}


var form = {
    name: { label: "Nombre", helperText: "Máximo 20 caracteres", inputProps: { maxLength: 20 }, onInput: (e) => { e.target.inputPosition = e.target.selectionStart }},
    surname: { label: "Apellido", helperText: "Máximo 20 caracteres",inputProps: { maxLength: 20 }, onInput: (e) => { e.target.inputPosition = e.target.selectionStart }},
    phone: {label: "Teléfono", type: "number"},
    email: { label: "Email", onInput: (e) => { e.target.inputPosition = e.target.selectionStart }}
}

export default function ClientForm(props) {
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                {Object.keys(form).map((field, i)=>{
                    return(
                        <Grid item xs={4} key={i}>
                            <CustomTextField 
                                onChange={(e) => props.onChange(e)} 
                                value={props.state["input-" + field]} 
                                id={"input-" + field} 
                                error={props.state["input-" + field+"-error"]}
                                {...form[field]} 
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </React.Fragment>
    );
}
