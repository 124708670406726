import React from 'react';
import MarkDownCard from "components/MarkDownCard"

class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = { text: "" }
    }

    async componentDidMount() {
        try {
            if (!this.state.text) {
                var { data, error } = await this.props.api.get("/inicio")
                if (error) throw error
                this.setState({ text: data.bienvenida })
            }
        } catch (e) { console.log(e) }
    }

    render() {
        return (
            <MarkDownCard text={this.state.text} />
        )
    }

}

export default Home;