var config = {}
if (process.env.NODE_ENV !== 'production'){
    config = {
        API: "http://localhost:1337"
    }
}
else{
    config = {
        API: "https://joyaslabrin.cl"
    }
}


export default config;