import Main from "pages/main";
import Home from "pages/home";
import Login from "pages/login";
import Jobs from "pages/jobs";

import DashboardIcon from '@material-ui/icons/Dashboard';
import AddWork from "pages/add_work"

import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PostAddIcon from '@material-ui/icons/PostAdd';


const routes = [
    {
        path: "/",
        component: Main,
        public: true,
        exact: true,
        name: "Main",
    },
    {
        path: "/inicio",
        component: Home,
        public: false,
        exact: true,
        name: "Inicio",
        menu: true,
        icon: DashboardIcon
    },
    {
        path: "/ingresar-trabajo",
        component: AddWork,
        public: false,
        exact: true,
        name: "Ingresar Trabajo",
        menu: true,
        privilege_index: 0,
        icon: PostAddIcon
    },
    {
        path: "/ingresar",
        component: Login,
        public: true,
        exact: true,
        name: "Ingresar",
        menu: false
    },
    {
        path: "/trabajos",
        component: Jobs,
        public: false,
        exact: true,
        name: "Trabajos",
        menu: true,
        icon: DescriptionIcon
    },
];

export default routes
