import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Button } from '@material-ui/core';
import functions from '_services/functions';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import JobDetails from './JobDetails';
import ExcelGenerator from './ExcelGenerator';
import BatchWorkStateChange from './BatchWorkStateChange';
import { Grid } from '@material-ui/core';

import { JobDocument } from './JobDocument';

import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function formatStringDate(d){
  var date = d.split("-")

  return date[2] + "-" + date[1] + "-" + date[0]
}



function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = props.seesClient ? [
    { id: 'doc_id', numeric: false, widht: "3%" ,disablePadding: false, label: 'Número de seguimiento' },
    { id: 'id', numeric: false, widht: "3%" ,disablePadding: false, label: 'Folio' },
    { id: 'client', numeric: false, widht: "6%", disablePadding: false, label: 'Rut' },
    { id: 'client_name', numeric: false, widht: "10%", disablePadding: false, label: 'Nombre cliente' },
    { id: 'object', numeric: false, disablePadding: false, label: 'Objeto' },
    { id: 'trademark', numeric: false, disablePadding: false, label: 'Marca' },
    { id: 'job', numeric: false, disablePadding: false, label: 'Trabajo' },
    { id: 'state', numeric: false, disablePadding: false, label: 'Estado' },
    { id: 'return_date', numeric: false, widht: "5%", disablePadding: false, label: 'Fecha entrega' },
    { id: 'updated_at', numeric: false, widht: "7%", disablePadding: false, label: 'Última actualización' },
  ] :
    [
      { id: 'id', numeric: false, widht: "5%",disablePadding: false, label: 'Folio' },
      { id: 'object', numeric: false, disablePadding: false, label: 'Objeto' },
      { id: 'trademark', numeric: false, disablePadding: false, label: 'Marca' },
      { id: 'job', numeric: false, disablePadding: false, label: 'Trabajo' },
      { id: 'state', numeric: false, disablePadding: false, label: 'Estado' },
      { id: 'return_date', numeric: false, widht: "5%", disablePadding: false, label: 'Fecha entrega' },
      { id: 'updated_at', numeric: false, widht: "7%", disablePadding: false, label: 'Última actualización' },
    ]
  ;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected >0}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Seleccionar todos los trabajos' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            width={headCell.widht ? headCell.widht:null}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label}</b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} seleccionados
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Trabajos
        </Typography>
      )}

      {numSelected > 0 && (
          <Tooltip title="Imprimir">
          <Button onClick={props.handlePrint}>Imprimir</Button>

          </Tooltip>
      )}
      {numSelected > 0 && (
          
          <Tooltip title="Cambiar estado">
            <Button onClick={props.handleOpenMultiple}>Cambiar estado</Button>

          </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal_paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '800px',
    maxHeight: '90%',
    overflow: 'auto',
  },
}));

export default function EnhancedTable(props) {
  const rows = props.rows;
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('updated_at');
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [row,setRow] = React.useState('');
  const [multipleSelected,setMultipleSelected] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.filter(function(r){return(r.is_final===false)}).map((n) => n.id);
      if (newSelecteds.length > 10){
        alert("Solo se pueden seleccionar 10 trabajos al mismo tiempo.")
        return
      }
      setSelected(newSelecteds);
      var tempSelectedData = props.allData.filter(
        function(record){
          return newSelecteds.includes(record.id);
        }
      )
      setSelectedData(tempSelectedData);
      return;
    }
    setSelected([]);
    setSelectedData([]);
    
  };


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if (newSelected.length > 10){
      alert("Solo se pueden seleccionar 10 trabajos al mismo tiempo.")
      return
    }

    setSelected(newSelected);
    var tempSelectedData = props.allData.filter(
        function(record){
          return newSelected.includes(record.id);
      }
    )
    setSelectedData(tempSelectedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  
  // Modal consts
  const [open, setOpen] = React.useState(false);

  const handleOpen = (selectedRow) => {
    setRow(selectedRow)
    setMultipleSelected(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenMultiple = () => {
    //setMultipleSelected(selected.length > 1);
    setMultipleSelected(true);
    setOpen(true);
  };
  const componentRef = useRef();
  const printRef = useRef();
  
  const handlePrint = useReactToPrint({
    content: () =>  componentRef.current,
  });

  const handlePrintPrevious = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    setSelected([]);
    setSelectedData([]);
    setPage(0);
  },
    [props.filterSwitch]);
  return (
    <div className={classes.root}>
      <div style={{ display: 'none' }}> <JobDocument style={{ size: 'landscape' }} auth={props.auth} ref={componentRef} noteAtEnd={false} selectedData={selectedData}/></div>
      <div style={{ display: 'none' }}> <JobDocument style={{ size: 'landscape' }} auth={props.auth} ref={printRef} noteAtEnd={true} selectedData={props.sentWorks} /></div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modal_paper}>
            {multipleSelected && 
              <BatchWorkStateChange
              states={props.outgoingStates}
              staff={props.staff}
              selectedData={selectedData}
              changeMultipleState={props.changeMultipleState}
              succSentWorks={props.sentWorks.length>0}
              handlePrintPrevious = {handlePrintPrevious}
              handleClose={handleClose}/>
            }
            {!multipleSelected &&
              <JobDetails row={row} allData={props.allData} initialState={props.initialState} handleClose={handleClose} updateWorkValues={props.updateWorkValues} api={props.api}/>
            }
          </div>
        </Fade>
      </Modal>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} handlePrint={handlePrint} handleOpenMultiple={handleOpenMultiple}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              seesClient={props.seesClient}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled = {row.is_final}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      {props.seesClient &&
                        <TableCell onClick={(evt) => { if (props.seesHistory) handleOpen(row) }} align="left">{row.doc_id}</TableCell>
                      }
                      <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} component="th" id={labelId} scope="row">
                        {row.id}
                      </TableCell>
                      { props.seesClient &&
                        <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} align="left">{row.client}</TableCell>
                      }
                      { props.seesClient &&
                        <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} align="left">{row.client_name}</TableCell>
                      }
                      <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} align="left" >{row.object}</TableCell>
                      <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} align="left">{row.trademark}</TableCell>
                      <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} align="left">{row.job}</TableCell>
                      <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} align="left">{row.state}</TableCell>
                      <TableCell onClick={(evt) => { if (props.seesHistory) handleOpen(row) }} align="left">{formatStringDate(row.return_date)}</TableCell>
                      <TableCell onClick={(evt) => {if (props.seesHistory) handleOpen(row)}} align="left">{functions.datetimeToLocalString(row.updated_at)}</TableCell>
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows}}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Grid
      justify="space-between" // Add it here :)
      container 
      spacing={24}
      >
        <Grid item>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Comprimir vista"
      />
      </Grid>
      {
        props.downloadsData &&
          <Grid item>
            <Tooltip title="Descargar datos">
              <span>
                <Button disabled={rows.length === 0} onClick={() => document.getElementById('excelGeneratorButton').click()}>Descargar datos</Button>
                <ExcelGenerator data={props.allData} />
              </span>
            </Tooltip>
          </Grid>
      }
      <Grid item>
      <Tooltip title="Imprimir últimos trabajos enviados">
        <span>
          <Button disabled={props.sentWorks.length===0} onClick={handlePrintPrevious}>Imprimir últimos trabajos enviados</Button>
        </span>
      </Tooltip>
        </Grid>
      </Grid>

    </div>
  );
}