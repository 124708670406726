import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        //display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
        custom_style: {
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
        },
    },
}));
function repositionCursor(e) {
    if (e.target?.selectionStart !== undefined && e.target?.inputPosition !== undefined) {
        window.requestAnimationFrame(() => {
            e.target.selectionStart = e.target.inputPosition
            e.target.selectionEnd = e.target.inputPosition
        })
    }
}

function amountFormat(ammount) {
    var pos = 0
    ammount = ammount.toString()
    ammount = ammount.split("").reverse().join("");
    var new_value = ""
    for (var num of ammount) {
        if (pos === 3) {
            new_value = "." + new_value
            pos = 0
        }
        new_value = num + new_value
        pos += 1
    }
    return new_value
}

export default function BatchWorkStateChange(props) {
    const classes = useStyles();
    const [selectedState, setSelectedState] = React.useState('');
    const [employee, setEmployee] = React.useState('');
    const [stateNote, setStateNote] = React.useState('');
    const [finalNote, setFinalNote] = React.useState('');
    const [final, setFinal] = React.useState(false);
    const states = props.states;
    const [gotResult, setGotResult] = React.useState(false);
    const [changeResult, setChangeResult] = React.useState("");
    const [paymentBill, setPaymentBill] = React.useState("");
    const [errorResult, setErrorResult] = React.useState(null);
    const [hideElement, setHideElement] = React.useState(false);
    const ids = props.selectedData?.map(d => { return d.id });
    var prepaid_value = 0
    var costs = []
    var total_cost = 0
    var total_prepaid = 0
    var same_document = true

    if (props.selectedData && props.selectedData.length > 0) {
        prepaid_value = props.selectedData[0].documento?.abono || 0
        var consolidated_prepaids = props.selectedData[0].documento?.abono_consolidado || []
        for (let i of consolidated_prepaids) {
            prepaid_value -= parseInt(i.valor_consolidado)
        }

        var document = props.selectedData[0].documento?.id

        for (let i = 0; i < props.selectedData.length; i++) {
            const work = props.selectedData[i];
            var current_pre_paid_value = prepaid_value > 0 ? (parseInt(prepaid_value) > parseInt(work.valor) + parseInt(work.costo_adicional) ? parseInt(work.valor) + parseInt(work.costo_adicional) : parseInt(prepaid_value)) : 0
            costs.push({
                folio: work.id,
                valor: parseInt(work.valor),
                costo_adicional: parseInt(work.costo_adicional),
                aditional_description: work.descripcion_costo_adicional,
                abono: current_pre_paid_value
            })
            prepaid_value -= (parseInt(work.valor) + parseInt(work.costo_adicional))
            total_cost += parseInt(work.valor) + parseInt(work.costo_adicional)
            total_prepaid += current_pre_paid_value

            if(work.documento?.id != document){
                same_document = false
            }
        }
    }


    const handleChange = (event) => {
        var state = states.filter(function (state) { return state.nombre == event.target.value })[0];
        if (state.es_final) {
            if (!same_document){
                alert("No se puede cambiar a un estado final trabajos de diferentes documentos.")
                return
            }
            setFinal(true)
        }
        else {
            setFinal(false)
        }
        setSelectedState(event.target.value);
    };
    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        setHideElement(true)
        var newState = states.filter(function (state) { return state.nombre == selectedState })[0];
        var result = await props.changeMultipleState(ids, newState, employee, final ? finalNote : stateNote, final, paymentBill, costs);
        if (result) {
            setChangeResult(false);
            setErrorResult(result);
        } else {
            setChangeResult(true);
            setErrorResult(null);
        }
        setGotResult(true);
        setHideElement(false)

    }
    console.log(gotResult, changeResult, final)

    // Formulario de cambio de estado

    if (!gotResult && hideElement) {
        return(
            <div>
                <CircularProgress />
            </div>
        )
    }

    if (!gotResult && !changeResult)
        return (
            <div>{
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h4 id="state-change">Cambiar de estado</h4>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="select-label">Seleccione estado</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="select"
                                    required
                                    value={selectedState}
                                    onChange={handleChange}
                                >
                                    {states.map(state => {
                                        return (
                                            <MenuItem value={state.nombre}> {state.nombre} </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        {!final && !changeResult &&
                            <Grid item xs={12}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="select-label">Seleccione empleado</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        id="select"
                                        required
                                        value={employee}
                                        onChange={(e) => { setEmployee(e.target.value) }}
                                    >
                                        <MenuItem value="NO APLICA"> {"NO APLICA"} </MenuItem>
                                        {props.staff && props.staff.map(s => {
                                            var apellido_2 = s.apellido_2 ? s.apellido_2 : ""
                                            var name = s.nombre + " " + s.apellido_1 + " " + apellido_2
                                            return (
                                                <MenuItem value={name}> {name} </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        }
                        {!final && !changeResult &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nota"
                                    id="filled-margin-dense-required"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="filled"
                                    value={stateNote}
                                    onChange={event => {
                                        setStateNote(event.target.value.toUpperCase())
                                        repositionCursor(event)
                                    }}
                                    multiline
                                    rows={4}
                                    onInput={((e) => { e.target.inputPosition = e.target.selectionStart })}
                                />
                            </Grid>
                        }
                        {
                            final &&
                            <Grid item xs={12}>
                                <h3 style={{ textAlign: "center" }}>Costos</h3>
                                <Grid container spacing={2}>

                                    {costs.map(work_cost => {
                                        return (
                                            <Grid item xs={4}>
                                                <ul>
                                                    <h4>Trabjo N°{amountFormat(work_cost.folio)}</h4>
                                                    <li><b>Valor:</b> {amountFormat(work_cost.valor)}</li>
                                                    <li><b>Valor adicional:</b> {amountFormat(work_cost.costo_adicional)}</li>
                                                    <li><b>Descripción costo adicional:</b> {work_cost.aditional_description}</li>
                                                    <li><b>Valor abono:</b> {amountFormat(work_cost.abono)}</li>
                                                </ul>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                <h3 style={{ textAlign: "center" }}>Costo total: {amountFormat(total_cost)}</h3>
                                <h3 style={{ textAlign: "center" }}>Abono total: {amountFormat(total_prepaid)}</h3>
                                <h3 style={{ textAlign: "center" }}>Por pagar: {amountFormat(total_cost - total_prepaid)}</h3>
                            </Grid>
                        }
                        {final &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required={prepaid_value < 0}
                                    label="Boleta de saldo"
                                    id="filled-margin-dense-required"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="filled"
                                    value={paymentBill}
                                    type="number"
                                    onChange={event => {
                                        setPaymentBill(event.target.value)
                                    }}
                                    onInput={((e) => { e.target.inputPosition = e.target.selectionStart })}
                                />
                            </Grid>
                        }
                        {final &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nota final"
                                    id="filled-margin-dense-required"
                                    helperText="Máximo 50 caracteres"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="filled"
                                    value={finalNote}
                                    inputProps={{ maxLength: 50 }}
                                    onChange={event => {
                                        setFinalNote(event.target.value.toUpperCase())
                                        repositionCursor(event)
                                    }}
                                    onInput={((e) => { e.target.inputPosition = e.target.selectionStart })}
                                    multiline
                                    rows={4}
                                />
                            </Grid>

                        }
                        <Grid item xs></Grid>
                        <Button type="submit" >Enviar</Button>

                    </Grid>
                </form>}

            </div>
        );

    // Mensaje de éxito de cambio de estado
    if (gotResult && changeResult && !final) return (
        <Grid container direction="row"
            justify="space-evenly"
            alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <h2 style={{ textAlign: 'center' }}>Operación realizada con éxito.</h2>
                <h3 style={{ textAlign: 'center' }}>¿Desea imprimir el documento de cambios de estado?</h3>

            </Grid>
            <Grid item xs={8}>
                <Grid
                    justify="space-between"
                    container
                    spacing={24}
                >
                    <Button onClick={e => {
                        props.handlePrintPrevious();
                        props.handleClose()
                    }} >
                        Sí</Button>

                    <Button onClick={e => props.handleClose()} >No</Button>

                </Grid>

            </Grid>
        </Grid>
    )
    if (gotResult && changeResult && final) return (
        <Grid container direction="row"
            justify="space-evenly"
            alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <h2 style={{ textAlign: 'center' }}>Operación realizada con éxito.</h2>
            </Grid>
            <Grid container>
                <Grid item xs></Grid>
                <Button onClick={e => props.handleClose()} >Ok</Button>
            </Grid>
        </Grid>
    )
    // Mensaje de error de cambio de estado
    if (gotResult && !changeResult) return (
        <Grid container direction="row"
            justify="space-evenly"
            alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <h3 style={{ textAlign: 'center' }}>Error al realizar la operación</h3>
            </Grid>
            {
                errorResult.errGet.length > 0 &&
                <Grid item xs={12}>
                    <p>Los siguientes trabajos no pudieron encontrarse en la base de datos:</p>
                    <ul>
                        {errorResult.errGet.map(id => { return <li>{id}</li> })}
                    </ul>
                </Grid>
            }
            {
                errorResult.errGet.length === 1 && errorResult.errGet[0] === -1 &&
                <Grid item xs={12}>
                    <p>No se pudo obtener ningún trabajo de la base de datos.</p>
                </Grid>
            }
            {
                errorResult.errUpd.length > 0 &&
                <Grid item xs={12}>
                    <p>Los siguientes trabajos no pudieron ser actualizados:</p>
                    <ul>
                        {errorResult.errUpd.map(id => { return <li>{id}</li> })}
                    </ul>
                </Grid>
            }
            {
                errorResult.errUpd.length === 1 && errorResult.errUpd[0] === -1 &&
                <Grid item xs={12}>
                    <p>No pudo actualizarse ningún trabajo en la base de datos.</p>
                </Grid>
            }
            {
                props.succSentWorks && !final &&
                <Grid container direction="row"
                    justify="space-evenly"
                    alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <h3 style={{ textAlign: 'center' }}>¿Desea imprimir el documento de cambios de estado para los trabajos actualizados?</h3>

                    </Grid>
                    <Grid item xs={8}>
                        <Grid
                            justify="space-between" // Add it here :)
                            container
                            spacing={24}
                        >
                            <Button onClick={e => {
                                props.handlePrintPrevious();
                                props.handleClose()
                            }} >
                                Sí</Button>

                            <Button onClick={e => props.handleClose()} >No</Button>

                        </Grid>

                    </Grid>
                </Grid>
            }
            {
                (!props.succSentWorks || final) &&
                <Grid container>
                    <Grid item xs></Grid>
                    <Button onClick={e => props.handleClose()} >Ok</Button>
                </Grid>
            }

        </Grid>
    )
}