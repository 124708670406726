import { jsPDF } from "jspdf";
import functions from "_services/functions"

function textLines(doc, text) {
    text = text.split(" ")
    var newText = ""
    var textSize = 0
    for (let i = 0; i < text.length; i++) {
        textSize += doc.getTextWidth(text[i])
        newText += text[i] + " "
        if (textSize > 50) {
            newText += "\n"
            textSize = 0
        }
    }
    return newText
}

function column(doc, text, x, y, align){
    return doc.text(text, x, y, { align: align })
}

export default async function generatePDF(data, api){
    var nombre = data.nombre ? data.nombre.toString() : ""
    var telefono = data.telefono ? data.telefono.toString() : ""
    var objeto = data.objeto ? data.objeto.toString() : ""
    var marca = data.marca ? data.marca.toString() : ""
    var trabajo = data.trabajo ? data.trabajo.toString() : ""
    var categoria = data.categoria ? data.categoria.toString() : ""
    var recepcion = data.recepcion ? data.recepcion.toString() : ""
    var entrega = data.entrega ? data.entrega.toString() : ""
    var observacion = data.observacion ? data.observacion.toString() : ""
    var id = data.id ? data.id.toString() : ""
    var valor = data.valor ? "$ " + functions.amountFormat(data.valor).toString() : ""
    var seguimiento = data.seguimiento ? data.seguimiento.toString() : ""
    var response = await api.get(`/documentos/${data.documento?.id}`, true)
    if  (response.error){
        alert("Error al obtener los documentos.")
        return
    }
    var documento = response.data
    var documento_id = documento.id.toString()
    var valor_total = 0
    var num_trabajos = 0
    for(let t of documento.trabajos){
        valor_total += parseInt(t.valor)
        num_trabajos += 1
    }
    valor_total = "$ " + functions.amountFormat(valor_total).toString()
    num_trabajos = num_trabajos.toString()
    var abono_total = documento?.abono ? "$ " + functions.amountFormat(documento?.abono).toString() : "$ 0"
    const unit = "mm";
    //const size = "A4"; // Use A1, A2, A3 or A4
    const size = [114, 254]
    const orientation = "portrait"; // portrait or landscape
    const font = "times"
    const default_fz = 9
    const doc = new jsPDF(orientation, unit, size);

    doc.setFont(font);
    doc.setFontSize(14);

    doc.setProperties({
        title: 'Folio: ' + id,
    });

    const header = (text, y) => {
        column(doc, text, 110, y, "right")
    }

    const title = (text, y) => column(doc, text, 56, y, "center")

    const distance_from_name = 2

    var custom_col = (text, x, y, align) => column(doc, text, x, y, align)

    var col1_start = 4
    var col_1_a = (text, y) => column(doc, text, col1_start, y, "left")
    var col_1_b = (text, y) => column(doc, text, col1_start+20, y, "left")
    var col_1_c = (text, y) => column(doc, text, col1_start+23, y, "left")

    var col2_start = 70
    var col_2_a = (text, y) => column(doc, text, col2_start, y, "left")
    var col_2_b = (text, y) => column(doc, text, col2_start+20, y, "left")
    var col_2_c = (text, y) => column(doc, text, col2_start+23, y, "left")

    var s1 = 5

    // Head
    doc.setFontSize(6);
    header("Cliente", s1)
    doc.setFontSize(default_fz);

    
    var row = s1 + 25
    // Column 1
    doc.setFont(font, 'bold')
    col_1_a("Nombre", row)
    col_1_b(":", row)
    doc.setFont(font, 'normal')
    col_1_c(nombre, row)
    // Column 2
    doc.setFontSize(11);
    doc.setFont(font, 'bold')
    custom_col("N° de seguimiento", 60,  row, "left")
    col_2_b(":", row)
    col_2_c(seguimiento, row)
    doc.setFontSize(default_fz);
    doc.setFont(font, 'normal')

    //SECTION 2
    var s2 = row + 30
    // Head
    doc.setFontSize(6);
    header("Control interno", s2)
    doc.setFontSize(default_fz);
    // Title
    doc.setFont(font, 'bold')
    title(categoria, s2 + 10)
    doc.setFont(font, 'normal')
    // Column 1
    var offset = 10
    var col = [
        { name: "Nombre", value: nombre, offset: 0 },
        { name: "Recepción", value: recepcion, offset: offset },
        { name: "Entrega", value: entrega, offset: offset },
        { name: "Trabajos", value: num_trabajos, offset: offset },
    ]
    var row_s2 = s2 + 25
    var y = row_s2
    for (let r of col) {
        y += r.offset
        var value = r.value
        if (value) {
            console.log(value)
            if (doc.getTextWidth(value) > 60) {
                value = textLines(doc, value)
            }
        }
        doc.setFont(font, 'bold')
        col_1_a(r.name, y)
        col_1_b(":", y)
        doc.setFont(font, 'normal')
        col_1_c(value, y)
    }

    // Columna 2 
    var col = [
        { name: "Folio", value: id, offset: 0 },
        { name: "Documento", value: documento_id, offset: offset },
        { name: "Valor", value: valor, offset: offset },
        { name: "Valor Total", value: valor_total, offset: offset },
        { name: "Abono Total", value: abono_total, offset: offset },
    ]
    var y = row_s2
    for (let r of col) {
        y += r.offset
        doc.setFont(font, 'bold')
        col_2_a(r.name, y)
        col_2_b(":", y)
        doc.setFont(font, 'normal')
        col_2_c(r.value, y)
    }

    //SECTION 3
    var s3 = y + 40
    // Head
    doc.setFontSize(6);
    header("Taller", s3)
    doc.setFontSize(default_fz);
    // Title
    doc.setFont(font, 'bold')
    title(categoria, s3 + 10)
    doc.setFont(font, 'normal')
    // Column 1
    var offset = 10
    var col = [
        { name: "Objeto", value: objeto, offset: 0 },
        { name: "Marca", value: marca, offset: offset },
        { name: "Trabajo", value: trabajo, offset: offset },
        { name: "Observación", value: observacion, offset: offset * 2 },
    ]
    var row_s3 = s3 + 25
    var y = row_s3
    for (let r of col) {
        y += r.offset
        var value = r.value
        if (value) {
            console.log(value)
            if (doc.getTextWidth(value)) {
                value = textLines(doc, value)
            }
        }
        doc.setFont(font, 'bold')
        col_1_a(r.name, y)
        col_1_b(":", y)
        doc.setFont(font, 'normal')
        col_1_c(value, y)
    }

    // Columna 2 
    var col = [
        { name: "Folio", value: id, offset: 0 },
    ]
    var y = row_s3
    for (let r of col) {
        y += r.offset
        doc.setFont(font, 'bold')
        col_2_a(r.name, y)
        col_2_b(":", y)
        doc.setFont(font, 'normal')
        col_2_c(r.value, y)
    }
    window.open(doc.output('bloburl'))
    //doc.save("test.pdf")
}